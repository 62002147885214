<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton
              :showBtn="false"
              :showAddNew="false"
              title="Contact List Import"
            />
            <input type="file" class="d-none" ref="uploader" @change="handleFileUpload">
        </div>

        <div class="row p-2">
            <div class="col-12 col-sm-6 col-md-5 col-lg-4">
                <label for="colFormLabel" class="col-form-label">Roles</label>
                <v-select
                    placeholder="Select Roles"
                    v-model="roleId"
                    :options="companyRoles"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div v-show="roleId" class="col-12 col-sm-6 col-md-4 col-lg-2 mt-auto">
                <button @click="uploadCsv" class="btn btn-primary waves-effect waves-float waves-light">
                    Browse
                </button>
            </div>
        </div>

        <div class="p-2">
            <div v-for="(item, index) in csvRow" :key="index">
                <div class="py-1 bg-black-light rounded-8 mb-2 d-flex justify-content-between align-items-center">
                    <span class="cursor-pointer px-1" @click="gotoUpload(item.id)">id: {{ item.id }} Dataset: {{ item.batch }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton.vue';
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import handleInventory from "@/services/modules/inventory";
import handleCompanyContact from "@/services/modules/companyContact";
import handleRole from "@/services/modules/role";

const { uploadContacts } = handleCompanyContact();
const { fetchCsvRow } = handleInventory();
const { fetchCompanyDefaultRoles } = handleRole();
const uploader = ref(null);
const uploadLog = ref([]);
const csvRow = ref([]);
const companyRoles = ref([])
const roleId = ref(null)

const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const gotoUpload = (id) => {
    const query = Object.assign({}, route.query);
    query.rowId = id;

    router.push({name: 'upload-contact', params: route.params, query: query});
}

const getCsvRow = async () => {
    let query = `?company_id=${route.params.companyId}&type=contact`;
    await fetchCsvRow(query).then((res) => {
        if(!res.status) {
            return
        }
        csvRow.value = res.data
    }).catch((e) => {
        console.log(e)
    });
}

const getRoles = async () => {
    let query = `?company_id=${route.params.companyId}`;
    await fetchCompanyDefaultRoles(query).then((res) => {
        if(!res.status) return;
        companyRoles.value = res.data
    })
}

const uploadCsv = () => {
    uploader.value.click();
}

const handleFileUpload = async (event) => {

    if(! roleId.value) {
        return showError('Please select role')
    }

    const selectedFile = event.target.files[0];

    let fd = new FormData();
    fd.append('company_id', route.params.companyId)
    fd.append('sheet', selectedFile)
    fd.append('type', 'contact');
    fd.append('role_id', roleId.value);
    
    await uploadContacts(fd).then((res) => {
        if(!res.status) {
            showError(res.message)
            return
        }
        
        showSuccess(res.message)
        getCsvRow();
    })
    .catch((e) => {
        showError('Something went wrong')
    });
}

onMounted(() => {
    getRoles();
    getCsvRow();
})

</script>